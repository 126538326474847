import "./custom-elements-es5-adapter.js";
import "document-register-element";
import "es6-promise/auto";
import "whatwg-fetch";
import "./custom-event-ie.js";
import "weakmap-polyfill";
import "./querySelector.polyfill.js";
import "./toggleAttribute.js";  // IE needs this, and other polyfills are lazy

import "./Disposable";

(async () => {
    if (/Trident\/|MSIE/.test(window.navigator.userAgent)) {
        await import( /* webpackChunkName: "internet-explorer" */ "./internetExplorerPolyfills");
    }
})();