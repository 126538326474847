import { CookieDialogElement } from "./CookieDialogElement";

type Choice = "no-choice" | "all-cookies" | "needed-cookies";

class CookieDialogActivator {
    private static choiceCookieKey = "cookie-choice";

    private get _choice() {
        const choice = this.getCookie(CookieDialogActivator.choiceCookieKey);
        if (choice !== "all-cookies" && choice !== "needed-cookies")
            return "no-choice";

        return choice;
    }


    private set _choice(value: Choice) {
        this.setCookie(CookieDialogActivator.choiceCookieKey, value, 360);
    }

    constructor() {
        console.log("choice is", this._choice);

        // initially we need no cookies if no choice
        if (this._choice === "no-choice" || this._choice === "needed-cookies")
            window['ga-disable-UA-20449215-1'] = true;

        if (this._choice === "no-choice")
            document.addEventListener("DOMContentLoaded", this.showDialog);
    }

    private showDialog = () => {
        const cookieDialog = new CookieDialogElement();
        document.body.append(cookieDialog);

        cookieDialog.addEventListener("click", (event: MouseEvent) => {
            if (!(event.target instanceof HTMLButtonElement))
                return;

            const button = event.target as HTMLButtonElement;

            if (button.name === "needed")
                this._choice = "needed-cookies";
            else if (button.name === "all")
                this._choice = "all-cookies";

            cookieDialog.remove();
        });
    }



    private setCookie(cName: string, cValue: string, expDays: number) {
        let date = new Date();
        date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
    }

    private getCookie(name: string): string {
        const b = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`);
        return b ? b.pop() : "";
    }
}

new CookieDialogActivator();




