import "./CookieDialogElement.less";

export class CookieDialogElement extends HTMLElement {
    static tag = "cookie-dialog";

    connectedCallback() {
        this.innerHTML = this.view();
    } 

    private view = () => `
        <div name=frame>
            <header>
                Hjemmesiden bruger cookies
            </header>
            <div>
                Nogle bruges til statistik, andre er nødvendige for at kunne logge ind.
            </div>
            <div>
                <button type=button name=needed class="no-class">Kun nødvendige cookies</button>
                <button type=button name=all primary class="no-class">Accepter alle</button>
            </div>
        </div>
    `;
} 

customElements.define(CookieDialogElement.tag, CookieDialogElement);